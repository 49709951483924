import React from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import ga from "../utils/ga"

/*
	this.props.gaEventOptions = [category, action, label]
	this.props.list overrides default mailchimp list in config when defined
*/
class ChimpForm extends React.Component {

	constructor(props){
		super(props);
		this.state={
			email: "",
			res:""
		}

	}

	handleChange = (event) => {
    	this.setState({email: event.target.value});
  	}

	handleSubmit = (e,email) => {
		e.preventDefault();
		addToMailchimp(email, this.props.fields, this.props.list).then(data => {
		  this.setState({email:data.result})
		})
	}

	gaEvent = () => {
		let gaEventOptions = this.props.gaEventOptions
		if(typeof gaEventOptions !== "undefined"){
			if(gaEventOptions.length === 3){
				ga(...gaEventOptions)
			}
		}
	}


    render() {
    	let classInput = (this.props.classInput === undefined) ? "" : this.props.classInput;
    	let classButton = (this.props.classButton === undefined) ? "" : this.props.classButton;
    	let classContainer = (this.props.classContainer === undefined) ? "" : this.props.classContainer;
    	let buttonText = (this.props.buttonText === undefined) ? "SUBMIT" : this.props.buttonText;
    	let placeholder = (this.props.placeholder === undefined) ? "your@email.com" : this.props.placeholder;

    	return(
		<div id="emailFormContainer">
			<form id="emailForm" onSubmit={(e)=>{this.handleSubmit(e, this.state.email)}}>
				<div className={`input-group ${classContainer}`}>
			        <input placeholder={placeholder} type="email" value={this.state.email} name="EMAIL" className={`form-control ${classInput}`} id="mce-EMAIL" onChange={this.handleChange}></input> 
			        {this.props.children}
			        <button  className={`btn btn-primary ${classButton}`} onClick={this.gaEvent}> {buttonText} </button>
			    </div>
		     </form>
		     <br></br>
		    <div>{this.state.res}</div>
        </div>
    	)
        
    }
}

export default ChimpForm;
