function ga(category, action, label) {

	console.log(`[GA EVENT]: ${category}, ${action}, ${label}`)

	if(typeof window.gtag !== "undefined"){
	    window.gtag('event', action, {
		  'event_category': category,
		  'event_label': label
		});
	}
}

export default ga